import React from "react"
import Layout from "../components/layout/layout-home"
import WorkHero from "../components/works/work-hero"
import WorksGrid from "../components/works/works-grid"
import { useStaticQuery } from "gatsby"
import { graphql } from 'gatsby'

const WorksPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        works(sort: "id:desc") {
          id
          year_of_creation
          name
          type
          thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <WorkHero />
      <WorksGrid
        data={data.strapi.works}
        slicedbyYear
        previewPath="pictures/"
      />
    </Layout>
  )
}

export default WorksPage
